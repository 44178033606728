import axios from 'axios'

export default defineNuxtPlugin(() => {
  const userAuthToken = localStorage.getItem('userAuthToken')
  let sessionKey = ''

  if (userAuthToken) {
    try {
      sessionKey = JSON.parse(userAuthToken)
    } catch (error) {
      console.error('Error parsing userAuthToken:', error)
    }
  }

  const api = axios.create({
    baseURL: process.env.API_BASE_URL,
    headers: {
      'X-API-Key': process.env.X_API_KEY,
      'X-Session-Key': sessionKey,
    },
  })

  return {
    provide: {
      api: api,
    },
  }
})
