export default defineNuxtPlugin((nuxtApp) => {
  const isOnline = ref(window.navigator.onLine)

  window.addEventListener('online', () => (isOnline.value = true))
  window.addEventListener('offline', () => (isOnline.value = false))

  return {
    provide: {
      isOnline,
    },
  }
})
