<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
import { useRouterHistoryStore } from '~/stores/router-history'
import axios, { AxiosError } from 'axios'
import { useToast } from 'vue-toastification'
const router = useRouter()
const routerHistory = useRouterHistoryStore()

onMounted(() => {
  const { locale } = useI18n()
  let language = window.navigator.language
  locale.value = language === 'he' ? 'he' : 'en'
})

router.afterEach((to, from, failure) => {
  if (!failure) {
    routerHistory.history.push(from)
  }
})

const toast = useToast()

axios.interceptors.response.use(undefined, (error) => {
  let errorMessage = undefined

  if (axios.isAxiosError(error)) {
    error = error as AxiosError
    const data = error.response.data

    if (data) {
      if (typeof data.message == 'string') {
        errorMessage = data.message
      }
    }
  }

  if (errorMessage) {
    toast.error(errorMessage, {
      rtl: true,
    })
  }
})
</script>
