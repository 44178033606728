import axios, { type AxiosInstance } from 'axios'
import { useAuthUserStore } from '~/stores/auth/auth-user'
import { storeToRefs } from 'pinia'

const apiClient: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    'X-API-Key': import.meta.env.VITE_X_API_KEY,
  },
})

apiClient.interceptors.request.use((config) => {
  const authStore = useAuthUserStore()
  const { userAuthToken } = storeToRefs(authStore)
  if (userAuthToken.value && config.headers) {
    config.headers['X-Session-Key'] = JSON.parse(userAuthToken.value)
  }
  return config
})

apiClient.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    console.log('adding-document__item flex text-nowrap gap-2', error)
    const authStore = useAuthUserStore()
    const router = useRouter()
    const { clearUser } = authStore
    const status = error.response.status
    const data = error.response.data

    if (status === 400 && data.isForceLogout) {
      clearUser()
    }
    // if(status !== undefined && data !== undefined) {
    else if (
      status === 400 &&
      data.errorCode === 15 &&
      data.message.includes('verify')
    ) {
      router.push('/auth/confirm')
    } else if (
      status === 400 &&
      data.errorCode === 63 &&
      data.message.includes('enough permission')
    ) {
      router.push('/not_allowed')
    } else if (
      status === 400 &&
      (data.errorCode === 82 || data.errorCode === 83) &&
      data.isSusbcriptionError
    ) {
      setTimeout(function () {
        window.open(
          `${import.meta.env.VITE_X_API_KEY}/pricing?url=${
            window.location.href
          }`,
          '_self',
        )
      }, 1500)
    }
    // }

    return Promise.reject(error)
  },
)

export default apiClient
