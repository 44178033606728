import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

export default defineNuxtPlugin((nuxtApp) => {
  const firebaseConfig = {
    apiKey: useRuntimeConfig().public.FIREBASE_API_KEY,
    authDomain: useRuntimeConfig().public.FIREBASE_AUTH_DOMAIN,
    projectId: useRuntimeConfig().public.FIREBASE_PROJECT_ID,
    storageBucket: useRuntimeConfig().public.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: useRuntimeConfig().public.FIREBASE_MESSAGING_SENDER_ID,
    appId: useRuntimeConfig().public.FIREBASE_APP_ID,
    measurementId: useRuntimeConfig().public.FIREBASE_MEASUREMENT_ID,
  }

  const app = initializeApp(firebaseConfig)
  const auth = getAuth()
  nuxtApp.vueApp.provide('auth', auth)
  nuxtApp.provide('auth', auth)
})
