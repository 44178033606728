import { default as already_signed_documenta3Fmb48EW9Meta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/already_signed_document.vue?macro=true";
import { default as change_passwordvo7NuDGdWsMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/auth/change_password.vue?macro=true";
import { default as confirm7ZcOHEdqQwMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/auth/confirm.vue?macro=true";
import { default as forgot_passwordvehS0Bet3JMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/auth/forgot_password.vue?macro=true";
import { default as reset_passwordVflbNpOPQQMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/auth/reset_password.vue?macro=true";
import { default as sign_fast_changeSMyl7dzEekMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/auth/sign_fast_change.vue?macro=true";
import { default as sign_inrxe0Zj0pPVMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/auth/sign_in.vue?macro=true";
import { default as sign_upUJBIfBOcalMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/auth/sign_up.vue?macro=true";
import { default as email_editorsOl8p3m1ytMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/email_editor.vue?macro=true";
import { default as checkingtmQGfHmmIRMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/file/[id]/checking.vue?macro=true";
import { default as indexUyaaA1dDYQMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/file/[id]/index.vue?macro=true";
import { default as messageIifpVCJ72sMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/file/[id]/message.vue?macro=true";
import { default as previewFu841XeBRZMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/file/[id]/preview.vue?macro=true";
import { default as shareQKHwykEZNaMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/file/[id]/share.vue?macro=true";
import { default as signed3wYQOKsyxtMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/file/[id]/signed.vue?macro=true";
import { default as template_previewESXXPZwMtXMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/file/[id]/template_preview.vue?macro=true";
import { default as indexZRslSYRXb0Meta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/index.vue?macro=true";
import { default as v1_integrateLaUJDnOQa7Meta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/integration/v1_integrate.vue?macro=true";
import { default as not_allowedqyoHSeLSuwMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/not_allowed.vue?macro=true";
import { default as fast_authbSR7sQVqcUMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signer/[id]/fast_auth.vue?macro=true";
import { default as indexwlbAAsbdwpMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signer/[id]/index.vue?macro=true";
import { default as previewsWDaKI7bM7Meta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signer/[id]/preview.vue?macro=true";
import { default as sentaDVzTB7yG6Meta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signer/[id]/sent.vue?macro=true";
import { default as signingOBpSEdSaqmMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signer/[id]/signing.vue?macro=true";
import { default as summarySCcEImUnXJMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signer/[id]/summary.vue?macro=true";
import { default as validationMPOJN8Y2x2Meta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signer/[id]/validation.vue?macro=true";
import { default as verificationlKxOQV1SUpMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signer/[id]/verification.vue?macro=true";
import { default as create_accountWWpxJSusOuMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signer/create_account.vue?macro=true";
import { default as create_passwordhDemaBHCTBMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signer/create_password.vue?macro=true";
import { default as indexYdj3fR6gh1Meta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/allDocuments/index.vue?macro=true";
import { default as indexYdN2lbirMmMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/auth/index.vue?macro=true";
import { default as successDcYOE3hv9rMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/auth/success.vue?macro=true";
import { default as contactsZ3QfQBqNIvMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/contacts.vue?macro=true";
import { default as dashboardr5zDtmQqdaMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/dashboard.vue?macro=true";
import { default as errorwb8LNqDiv3Meta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/error.vue?macro=true";
import { default as all_documentsXnVFEuwYpLMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/explorer/all_documents.vue?macro=true";
import { default as index8LCunh2n8nMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/explorer/contacts/index.vue?macro=true";
import { default as formsVAHibf4SuqMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/explorer/forms.vue?macro=true";
import { default as indexeqBzQi9H1kMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/explorer/groups/index.vue?macro=true";
import { default as indextoVRX9MjZCMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/explorer/index.vue?macro=true";
import { default as old_45contactsVsQGmvxPAeMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/explorer/old-contacts.vue?macro=true";
import { default as templatesVXxwD6OeJ2Meta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/explorer/templates.vue?macro=true";
import { default as trashyuurrQ0PNVMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/explorer/trash.vue?macro=true";
import { default as indexwtuMlr5uLkMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/index.vue?macro=true";
import { default as documentsNZXhQtrveeMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/my_account/documents.vue?macro=true";
import { default as filesahCI7KGq3NMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/my_account/files.vue?macro=true";
import { default as indexvO5f9utMATMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/my_account/index.vue?macro=true";
import { default as organizationsNMvaBqf80PMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/my_account/organizations.vue?macro=true";
import { default as profile7uZYjhgeZzMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/my_account/profile.vue?macro=true";
import { default as indexNCXuG9nSmSMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/my_account/settings/index.vue?macro=true";
import { default as my_account5gr1tI3tiSMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/my_account.vue?macro=true";
import { default as pdf_creatorQlKwHq3C7YMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/pdf_creator.vue?macro=true";
import { default as pdf_viewerTCuG7XysMJMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/pdf_viewer.vue?macro=true";
import { default as plansKiM0jrw6VrMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/plans.vue?macro=true";
import { default as upload_documentrrX3TXQtBiMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/upload_document.vue?macro=true";
import { default as v1_integrationRIVyhmwachMeta } from "/home/runner/work/DFSystems-front/DFSystems-front/pages/v1_integration.vue?macro=true";
export default [
  {
    name: already_signed_documenta3Fmb48EW9Meta?.name ?? "already_signed_document",
    path: already_signed_documenta3Fmb48EW9Meta?.path ?? "/already_signed_document",
    meta: already_signed_documenta3Fmb48EW9Meta || {},
    alias: already_signed_documenta3Fmb48EW9Meta?.alias || [],
    redirect: already_signed_documenta3Fmb48EW9Meta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/already_signed_document.vue").then(m => m.default || m)
  },
  {
    name: change_passwordvo7NuDGdWsMeta?.name ?? "auth-change_password",
    path: change_passwordvo7NuDGdWsMeta?.path ?? "/auth/change_password",
    meta: change_passwordvo7NuDGdWsMeta || {},
    alias: change_passwordvo7NuDGdWsMeta?.alias || [],
    redirect: change_passwordvo7NuDGdWsMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/auth/change_password.vue").then(m => m.default || m)
  },
  {
    name: confirm7ZcOHEdqQwMeta?.name ?? "auth-confirm",
    path: confirm7ZcOHEdqQwMeta?.path ?? "/auth/confirm",
    meta: confirm7ZcOHEdqQwMeta || {},
    alias: confirm7ZcOHEdqQwMeta?.alias || [],
    redirect: confirm7ZcOHEdqQwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/auth/confirm.vue").then(m => m.default || m)
  },
  {
    name: forgot_passwordvehS0Bet3JMeta?.name ?? "auth-forgot_password",
    path: forgot_passwordvehS0Bet3JMeta?.path ?? "/auth/forgot_password",
    meta: forgot_passwordvehS0Bet3JMeta || {},
    alias: forgot_passwordvehS0Bet3JMeta?.alias || [],
    redirect: forgot_passwordvehS0Bet3JMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/auth/forgot_password.vue").then(m => m.default || m)
  },
  {
    name: reset_passwordVflbNpOPQQMeta?.name ?? "auth-reset_password",
    path: reset_passwordVflbNpOPQQMeta?.path ?? "/auth/reset_password",
    meta: reset_passwordVflbNpOPQQMeta || {},
    alias: reset_passwordVflbNpOPQQMeta?.alias || [],
    redirect: reset_passwordVflbNpOPQQMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/auth/reset_password.vue").then(m => m.default || m)
  },
  {
    name: sign_fast_changeSMyl7dzEekMeta?.name ?? "auth-sign_fast_change",
    path: sign_fast_changeSMyl7dzEekMeta?.path ?? "/auth/sign_fast_change",
    meta: sign_fast_changeSMyl7dzEekMeta || {},
    alias: sign_fast_changeSMyl7dzEekMeta?.alias || [],
    redirect: sign_fast_changeSMyl7dzEekMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/auth/sign_fast_change.vue").then(m => m.default || m)
  },
  {
    name: sign_inrxe0Zj0pPVMeta?.name ?? "auth-sign_in",
    path: sign_inrxe0Zj0pPVMeta?.path ?? "/auth/sign_in",
    meta: sign_inrxe0Zj0pPVMeta || {},
    alias: sign_inrxe0Zj0pPVMeta?.alias || [],
    redirect: sign_inrxe0Zj0pPVMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/auth/sign_in.vue").then(m => m.default || m)
  },
  {
    name: sign_upUJBIfBOcalMeta?.name ?? "auth-sign_up",
    path: sign_upUJBIfBOcalMeta?.path ?? "/auth/sign_up",
    meta: sign_upUJBIfBOcalMeta || {},
    alias: sign_upUJBIfBOcalMeta?.alias || [],
    redirect: sign_upUJBIfBOcalMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/auth/sign_up.vue").then(m => m.default || m)
  },
  {
    name: email_editorsOl8p3m1ytMeta?.name ?? "email_editor",
    path: email_editorsOl8p3m1ytMeta?.path ?? "/email_editor",
    meta: email_editorsOl8p3m1ytMeta || {},
    alias: email_editorsOl8p3m1ytMeta?.alias || [],
    redirect: email_editorsOl8p3m1ytMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/email_editor.vue").then(m => m.default || m)
  },
  {
    name: checkingtmQGfHmmIRMeta?.name ?? "file-id-checking",
    path: checkingtmQGfHmmIRMeta?.path ?? "/file/:id()/checking",
    meta: checkingtmQGfHmmIRMeta || {},
    alias: checkingtmQGfHmmIRMeta?.alias || [],
    redirect: checkingtmQGfHmmIRMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/file/[id]/checking.vue").then(m => m.default || m)
  },
  {
    name: indexUyaaA1dDYQMeta?.name ?? "file-id",
    path: indexUyaaA1dDYQMeta?.path ?? "/file/:id()",
    meta: indexUyaaA1dDYQMeta || {},
    alias: indexUyaaA1dDYQMeta?.alias || [],
    redirect: indexUyaaA1dDYQMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/file/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: messageIifpVCJ72sMeta?.name ?? "file-id-message",
    path: messageIifpVCJ72sMeta?.path ?? "/file/:id()/message",
    meta: messageIifpVCJ72sMeta || {},
    alias: messageIifpVCJ72sMeta?.alias || [],
    redirect: messageIifpVCJ72sMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/file/[id]/message.vue").then(m => m.default || m)
  },
  {
    name: previewFu841XeBRZMeta?.name ?? "file-id-preview",
    path: previewFu841XeBRZMeta?.path ?? "/file/:id()/preview",
    meta: previewFu841XeBRZMeta || {},
    alias: previewFu841XeBRZMeta?.alias || [],
    redirect: previewFu841XeBRZMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/file/[id]/preview.vue").then(m => m.default || m)
  },
  {
    name: shareQKHwykEZNaMeta?.name ?? "file-id-share",
    path: shareQKHwykEZNaMeta?.path ?? "/file/:id()/share",
    meta: shareQKHwykEZNaMeta || {},
    alias: shareQKHwykEZNaMeta?.alias || [],
    redirect: shareQKHwykEZNaMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/file/[id]/share.vue").then(m => m.default || m)
  },
  {
    name: signed3wYQOKsyxtMeta?.name ?? "file-id-signed",
    path: signed3wYQOKsyxtMeta?.path ?? "/file/:id()/signed",
    meta: signed3wYQOKsyxtMeta || {},
    alias: signed3wYQOKsyxtMeta?.alias || [],
    redirect: signed3wYQOKsyxtMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/file/[id]/signed.vue").then(m => m.default || m)
  },
  {
    name: template_previewESXXPZwMtXMeta?.name ?? "file-id-template_preview",
    path: template_previewESXXPZwMtXMeta?.path ?? "/file/:id()/template_preview",
    meta: template_previewESXXPZwMtXMeta || {},
    alias: template_previewESXXPZwMtXMeta?.alias || [],
    redirect: template_previewESXXPZwMtXMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/file/[id]/template_preview.vue").then(m => m.default || m)
  },
  {
    name: indexZRslSYRXb0Meta?.name ?? "index",
    path: indexZRslSYRXb0Meta?.path ?? "/",
    meta: indexZRslSYRXb0Meta || {},
    alias: indexZRslSYRXb0Meta?.alias || [],
    redirect: indexZRslSYRXb0Meta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/index.vue").then(m => m.default || m)
  },
  {
    name: v1_integrateLaUJDnOQa7Meta?.name ?? "integration-v1_integrate",
    path: v1_integrateLaUJDnOQa7Meta?.path ?? "/integration/v1_integrate",
    meta: v1_integrateLaUJDnOQa7Meta || {},
    alias: v1_integrateLaUJDnOQa7Meta?.alias || [],
    redirect: v1_integrateLaUJDnOQa7Meta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/integration/v1_integrate.vue").then(m => m.default || m)
  },
  {
    name: not_allowedqyoHSeLSuwMeta?.name ?? "not_allowed",
    path: not_allowedqyoHSeLSuwMeta?.path ?? "/not_allowed",
    meta: not_allowedqyoHSeLSuwMeta || {},
    alias: not_allowedqyoHSeLSuwMeta?.alias || [],
    redirect: not_allowedqyoHSeLSuwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/not_allowed.vue").then(m => m.default || m)
  },
  {
    name: fast_authbSR7sQVqcUMeta?.name ?? "signer-id-fast_auth",
    path: fast_authbSR7sQVqcUMeta?.path ?? "/signer/:id()/fast_auth",
    meta: fast_authbSR7sQVqcUMeta || {},
    alias: fast_authbSR7sQVqcUMeta?.alias || [],
    redirect: fast_authbSR7sQVqcUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signer/[id]/fast_auth.vue").then(m => m.default || m)
  },
  {
    name: indexwlbAAsbdwpMeta?.name ?? "signer-id",
    path: indexwlbAAsbdwpMeta?.path ?? "/signer/:id()",
    meta: indexwlbAAsbdwpMeta || {},
    alias: indexwlbAAsbdwpMeta?.alias || [],
    redirect: indexwlbAAsbdwpMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signer/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: previewsWDaKI7bM7Meta?.name ?? "signer-id-preview",
    path: previewsWDaKI7bM7Meta?.path ?? "/signer/:id()/preview",
    meta: previewsWDaKI7bM7Meta || {},
    alias: previewsWDaKI7bM7Meta?.alias || [],
    redirect: previewsWDaKI7bM7Meta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signer/[id]/preview.vue").then(m => m.default || m)
  },
  {
    name: sentaDVzTB7yG6Meta?.name ?? "signer-id-sent",
    path: sentaDVzTB7yG6Meta?.path ?? "/signer/:id()/sent",
    meta: sentaDVzTB7yG6Meta || {},
    alias: sentaDVzTB7yG6Meta?.alias || [],
    redirect: sentaDVzTB7yG6Meta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signer/[id]/sent.vue").then(m => m.default || m)
  },
  {
    name: signingOBpSEdSaqmMeta?.name ?? "signer-id-signing",
    path: signingOBpSEdSaqmMeta?.path ?? "/signer/:id()/signing",
    meta: signingOBpSEdSaqmMeta || {},
    alias: signingOBpSEdSaqmMeta?.alias || [],
    redirect: signingOBpSEdSaqmMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signer/[id]/signing.vue").then(m => m.default || m)
  },
  {
    name: summarySCcEImUnXJMeta?.name ?? "signer-id-summary",
    path: summarySCcEImUnXJMeta?.path ?? "/signer/:id()/summary",
    meta: summarySCcEImUnXJMeta || {},
    alias: summarySCcEImUnXJMeta?.alias || [],
    redirect: summarySCcEImUnXJMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signer/[id]/summary.vue").then(m => m.default || m)
  },
  {
    name: validationMPOJN8Y2x2Meta?.name ?? "signer-id-validation",
    path: validationMPOJN8Y2x2Meta?.path ?? "/signer/:id()/validation",
    meta: validationMPOJN8Y2x2Meta || {},
    alias: validationMPOJN8Y2x2Meta?.alias || [],
    redirect: validationMPOJN8Y2x2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signer/[id]/validation.vue").then(m => m.default || m)
  },
  {
    name: verificationlKxOQV1SUpMeta?.name ?? "signer-id-verification",
    path: verificationlKxOQV1SUpMeta?.path ?? "/signer/:id()/verification",
    meta: verificationlKxOQV1SUpMeta || {},
    alias: verificationlKxOQV1SUpMeta?.alias || [],
    redirect: verificationlKxOQV1SUpMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signer/[id]/verification.vue").then(m => m.default || m)
  },
  {
    name: create_accountWWpxJSusOuMeta?.name ?? "signer-create_account",
    path: create_accountWWpxJSusOuMeta?.path ?? "/signer/create_account",
    meta: create_accountWWpxJSusOuMeta || {},
    alias: create_accountWWpxJSusOuMeta?.alias || [],
    redirect: create_accountWWpxJSusOuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signer/create_account.vue").then(m => m.default || m)
  },
  {
    name: create_passwordhDemaBHCTBMeta?.name ?? "signer-create_password",
    path: create_passwordhDemaBHCTBMeta?.path ?? "/signer/create_password",
    meta: create_passwordhDemaBHCTBMeta || {},
    alias: create_passwordhDemaBHCTBMeta?.alias || [],
    redirect: create_passwordhDemaBHCTBMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signer/create_password.vue").then(m => m.default || m)
  },
  {
    name: indexYdj3fR6gh1Meta?.name ?? "signy-allDocuments",
    path: indexYdj3fR6gh1Meta?.path ?? "/signy/allDocuments",
    meta: indexYdj3fR6gh1Meta || {},
    alias: indexYdj3fR6gh1Meta?.alias || [],
    redirect: indexYdj3fR6gh1Meta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/allDocuments/index.vue").then(m => m.default || m)
  },
  {
    name: indexYdN2lbirMmMeta?.name ?? "signy-auth",
    path: indexYdN2lbirMmMeta?.path ?? "/signy/auth",
    meta: indexYdN2lbirMmMeta || {},
    alias: indexYdN2lbirMmMeta?.alias || [],
    redirect: indexYdN2lbirMmMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/auth/index.vue").then(m => m.default || m)
  },
  {
    name: successDcYOE3hv9rMeta?.name ?? "signy-auth-success",
    path: successDcYOE3hv9rMeta?.path ?? "/signy/auth/success",
    meta: successDcYOE3hv9rMeta || {},
    alias: successDcYOE3hv9rMeta?.alias || [],
    redirect: successDcYOE3hv9rMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/auth/success.vue").then(m => m.default || m)
  },
  {
    name: contactsZ3QfQBqNIvMeta?.name ?? "signy-contacts",
    path: contactsZ3QfQBqNIvMeta?.path ?? "/signy/contacts",
    meta: contactsZ3QfQBqNIvMeta || {},
    alias: contactsZ3QfQBqNIvMeta?.alias || [],
    redirect: contactsZ3QfQBqNIvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/contacts.vue").then(m => m.default || m)
  },
  {
    name: dashboardr5zDtmQqdaMeta?.name ?? "signy-dashboard",
    path: dashboardr5zDtmQqdaMeta?.path ?? "/signy/dashboard",
    meta: dashboardr5zDtmQqdaMeta || {},
    alias: dashboardr5zDtmQqdaMeta?.alias || [],
    redirect: dashboardr5zDtmQqdaMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/dashboard.vue").then(m => m.default || m)
  },
  {
    name: errorwb8LNqDiv3Meta?.name ?? "signy-error",
    path: errorwb8LNqDiv3Meta?.path ?? "/signy/error",
    meta: errorwb8LNqDiv3Meta || {},
    alias: errorwb8LNqDiv3Meta?.alias || [],
    redirect: errorwb8LNqDiv3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/error.vue").then(m => m.default || m)
  },
  {
    name: all_documentsXnVFEuwYpLMeta?.name ?? "signy-explorer-all_documents",
    path: all_documentsXnVFEuwYpLMeta?.path ?? "/signy/explorer/all_documents",
    meta: all_documentsXnVFEuwYpLMeta || {},
    alias: all_documentsXnVFEuwYpLMeta?.alias || [],
    redirect: all_documentsXnVFEuwYpLMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/explorer/all_documents.vue").then(m => m.default || m)
  },
  {
    name: index8LCunh2n8nMeta?.name ?? "signy-explorer-contacts",
    path: index8LCunh2n8nMeta?.path ?? "/signy/explorer/contacts",
    meta: index8LCunh2n8nMeta || {},
    alias: index8LCunh2n8nMeta?.alias || [],
    redirect: index8LCunh2n8nMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/explorer/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: formsVAHibf4SuqMeta?.name ?? "signy-explorer-forms",
    path: formsVAHibf4SuqMeta?.path ?? "/signy/explorer/forms",
    meta: formsVAHibf4SuqMeta || {},
    alias: formsVAHibf4SuqMeta?.alias || [],
    redirect: formsVAHibf4SuqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/explorer/forms.vue").then(m => m.default || m)
  },
  {
    name: indexeqBzQi9H1kMeta?.name ?? "signy-explorer-groups",
    path: indexeqBzQi9H1kMeta?.path ?? "/signy/explorer/groups",
    meta: indexeqBzQi9H1kMeta || {},
    alias: indexeqBzQi9H1kMeta?.alias || [],
    redirect: indexeqBzQi9H1kMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/explorer/groups/index.vue").then(m => m.default || m)
  },
  {
    name: indextoVRX9MjZCMeta?.name ?? "signy-explorer",
    path: indextoVRX9MjZCMeta?.path ?? "/signy/explorer",
    meta: indextoVRX9MjZCMeta || {},
    alias: indextoVRX9MjZCMeta?.alias || [],
    redirect: indextoVRX9MjZCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/explorer/index.vue").then(m => m.default || m)
  },
  {
    name: old_45contactsVsQGmvxPAeMeta?.name ?? "signy-explorer-old-contacts",
    path: old_45contactsVsQGmvxPAeMeta?.path ?? "/signy/explorer/old-contacts",
    meta: old_45contactsVsQGmvxPAeMeta || {},
    alias: old_45contactsVsQGmvxPAeMeta?.alias || [],
    redirect: old_45contactsVsQGmvxPAeMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/explorer/old-contacts.vue").then(m => m.default || m)
  },
  {
    name: templatesVXxwD6OeJ2Meta?.name ?? "signy-explorer-templates",
    path: templatesVXxwD6OeJ2Meta?.path ?? "/signy/explorer/templates",
    meta: templatesVXxwD6OeJ2Meta || {},
    alias: templatesVXxwD6OeJ2Meta?.alias || [],
    redirect: templatesVXxwD6OeJ2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/explorer/templates.vue").then(m => m.default || m)
  },
  {
    name: trashyuurrQ0PNVMeta?.name ?? "signy-explorer-trash",
    path: trashyuurrQ0PNVMeta?.path ?? "/signy/explorer/trash",
    meta: trashyuurrQ0PNVMeta || {},
    alias: trashyuurrQ0PNVMeta?.alias || [],
    redirect: trashyuurrQ0PNVMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/explorer/trash.vue").then(m => m.default || m)
  },
  {
    name: indexwtuMlr5uLkMeta?.name ?? "signy",
    path: indexwtuMlr5uLkMeta?.path ?? "/signy",
    meta: indexwtuMlr5uLkMeta || {},
    alias: indexwtuMlr5uLkMeta?.alias || [],
    redirect: indexwtuMlr5uLkMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/index.vue").then(m => m.default || m)
  },
  {
    path: my_account5gr1tI3tiSMeta?.path ?? "/signy/my_account",
    children: [
  {
    name: documentsNZXhQtrveeMeta?.name ?? "signy-my_account-documents",
    path: documentsNZXhQtrveeMeta?.path ?? "documents",
    meta: documentsNZXhQtrveeMeta || {},
    alias: documentsNZXhQtrveeMeta?.alias || [],
    redirect: documentsNZXhQtrveeMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/my_account/documents.vue").then(m => m.default || m)
  },
  {
    name: filesahCI7KGq3NMeta?.name ?? "signy-my_account-files",
    path: filesahCI7KGq3NMeta?.path ?? "files",
    meta: filesahCI7KGq3NMeta || {},
    alias: filesahCI7KGq3NMeta?.alias || [],
    redirect: filesahCI7KGq3NMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/my_account/files.vue").then(m => m.default || m)
  },
  {
    name: indexvO5f9utMATMeta?.name ?? "signy-my_account",
    path: indexvO5f9utMATMeta?.path ?? "",
    meta: indexvO5f9utMATMeta || {},
    alias: indexvO5f9utMATMeta?.alias || [],
    redirect: indexvO5f9utMATMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/my_account/index.vue").then(m => m.default || m)
  },
  {
    name: organizationsNMvaBqf80PMeta?.name ?? "signy-my_account-organizations",
    path: organizationsNMvaBqf80PMeta?.path ?? "organizations",
    meta: organizationsNMvaBqf80PMeta || {},
    alias: organizationsNMvaBqf80PMeta?.alias || [],
    redirect: organizationsNMvaBqf80PMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/my_account/organizations.vue").then(m => m.default || m)
  },
  {
    name: profile7uZYjhgeZzMeta?.name ?? "signy-my_account-profile",
    path: profile7uZYjhgeZzMeta?.path ?? "profile",
    meta: profile7uZYjhgeZzMeta || {},
    alias: profile7uZYjhgeZzMeta?.alias || [],
    redirect: profile7uZYjhgeZzMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/my_account/profile.vue").then(m => m.default || m)
  },
  {
    name: indexNCXuG9nSmSMeta?.name ?? "signy-my_account-settings",
    path: indexNCXuG9nSmSMeta?.path ?? "settings",
    meta: indexNCXuG9nSmSMeta || {},
    alias: indexNCXuG9nSmSMeta?.alias || [],
    redirect: indexNCXuG9nSmSMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/my_account/settings/index.vue").then(m => m.default || m)
  }
],
    name: my_account5gr1tI3tiSMeta?.name ?? undefined,
    meta: my_account5gr1tI3tiSMeta || {},
    alias: my_account5gr1tI3tiSMeta?.alias || [],
    redirect: my_account5gr1tI3tiSMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/my_account.vue").then(m => m.default || m)
  },
  {
    name: pdf_creatorQlKwHq3C7YMeta?.name ?? "signy-pdf_creator",
    path: pdf_creatorQlKwHq3C7YMeta?.path ?? "/signy/pdf_creator",
    meta: pdf_creatorQlKwHq3C7YMeta || {},
    alias: pdf_creatorQlKwHq3C7YMeta?.alias || [],
    redirect: pdf_creatorQlKwHq3C7YMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/pdf_creator.vue").then(m => m.default || m)
  },
  {
    name: pdf_viewerTCuG7XysMJMeta?.name ?? "signy-pdf_viewer",
    path: pdf_viewerTCuG7XysMJMeta?.path ?? "/signy/pdf_viewer",
    meta: pdf_viewerTCuG7XysMJMeta || {},
    alias: pdf_viewerTCuG7XysMJMeta?.alias || [],
    redirect: pdf_viewerTCuG7XysMJMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/pdf_viewer.vue").then(m => m.default || m)
  },
  {
    name: plansKiM0jrw6VrMeta?.name ?? "signy-plans",
    path: plansKiM0jrw6VrMeta?.path ?? "/signy/plans",
    meta: plansKiM0jrw6VrMeta || {},
    alias: plansKiM0jrw6VrMeta?.alias || [],
    redirect: plansKiM0jrw6VrMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/plans.vue").then(m => m.default || m)
  },
  {
    name: upload_documentrrX3TXQtBiMeta?.name ?? "signy-upload_document",
    path: upload_documentrrX3TXQtBiMeta?.path ?? "/signy/upload_document",
    meta: upload_documentrrX3TXQtBiMeta || {},
    alias: upload_documentrrX3TXQtBiMeta?.alias || [],
    redirect: upload_documentrrX3TXQtBiMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/signy/upload_document.vue").then(m => m.default || m)
  },
  {
    name: v1_integrationRIVyhmwachMeta?.name ?? "v1_integration",
    path: v1_integrationRIVyhmwachMeta?.path ?? "/v1_integration",
    meta: v1_integrationRIVyhmwachMeta || {},
    alias: v1_integrationRIVyhmwachMeta?.alias || [],
    redirect: v1_integrationRIVyhmwachMeta?.redirect || undefined,
    component: () => import("/home/runner/work/DFSystems-front/DFSystems-front/pages/v1_integration.vue").then(m => m.default || m)
  }
]