import Toast, { PluginOptions } from 'vue-toastification'
import 'vue-toastification/dist/index.css'

export default defineNuxtPlugin(({ vueApp }) => {
  const options: PluginOptions = {
    // You can set your default options here
    hideProgressBar: true,
    transition: 'Vue-Toastification__fade',
    position: 'bottom-right',
    toastClassName: 'my-toast-classname',
  }

  vueApp.use(Toast, options)
})
