import { defineStore } from 'pinia'
import http from '@/services/http-common'
import axios from 'axios'
import { useProfileStore } from '~/stores/profile/profile'

interface State {
  userPhone: string
  userEmail: string
  userPassword: string
  userAuthToken: any
  userConfirmCode: any
  wrongMessage: string
  facebookToken: string
  errorEmail: boolean
  isSignByToken: boolean
}

export const useAuthUserStore = defineStore('authUser', {
  // convert to a function
  state: (): State => ({
    userPhone: '',
    userEmail: '',
    userPassword: '',
    userAuthToken: localStorage.getItem('userAuthToken') || '',
    userConfirmCode: '',
    wrongMessage: '',
    facebookToken: '',
    errorEmail: false,
    isSignByToken: true,
  }),
  actions: {
    async signInUser() {
      this.isSignByToken = false
      const isUsePhone =
        localStorage.getItem('usePhone') !== null &&
        localStorage.getItem('usePhone') !== undefined &&
        localStorage.getItem('usePhone') === 'true'
      const queryData = isUsePhone
        ? {
            email: this.userPhone,
            password: this.userPassword,
          }
        : {
            email: this.userEmail,
            password: this.userPassword,
          }
      let wrongMessageAuth = ''

      const response: any = await http
        .post('/auth/sign-in', queryData)
        .catch(function (error) {
          if (error.response) {
            if (error.response.data.errorCode === 6) {
              wrongMessageAuth = isUsePhone
                ? 'This number is not registered'
                : 'This email is not registered'
            } else if (error.response.data.errorCode === 41) {
              wrongMessageAuth =
                'Please, try one more time, the password or email is wrong.'
            } else if (error.response.data.errorCode === 42) {
              wrongMessageAuth =
                'Please, try one more time, the password or email is wrong.'
            }
          } else {
            wrongMessageAuth = ''
          }
        })
      this.wrongMessage = wrongMessageAuth

      if (!response) {
        return
      }

      localStorage.setItem(
        'userAuthToken',
        JSON.stringify(response.data.accessToken),
      )
      this.userAuthToken = JSON.stringify(response.data.accessToken)

      if (useRoute().query.to && typeof useRoute().query.to == 'string') {
        return useRouter().push(
          decodeURIComponent(useRoute().query.to as string),
        )
      }

      if (
        response.data.sessionUser.isEmailVerified ||
        response.data.sessionUser.isPhoneVerified
      ) {
        this.getSignyDocument(response.data.sessionUser)

        // useRouter().push('/auth/sign_fast_change')
      } else {
        isUsePhone
          ? localStorage.setItem('userPhone', JSON.stringify(this.userPhone))
          : localStorage.setItem('userEmail', JSON.stringify(this.userEmail))
        localStorage.setItem(
          'emailConfirmStartTime',
          JSON.stringify(Date.now()),
        )
        useRouter().push('/auth/confirm')
      }
    },
    async signInByToken(queryData: string) {
      this.isSignByToken = true
      const response: any = await http
        .post('/auth/sign-in-by-token', { token: queryData })
        .catch(function (error) {})

      if (response.data.accessToken) {
        localStorage.setItem(
          'userAuthToken',
          JSON.stringify(response.data.accessToken),
        )
        this.userAuthToken = JSON.stringify(response.data.accessToken)
      }
    },
    async googleSignInUser(payload: any) {
      this.isSignByToken = false
      const response: any = await http.post('/auth/google-sign-in', {
        accessToken: payload.googleToken,
      })
      if (response.data.accessToken) {
        localStorage.setItem(
          'userAuthToken',
          JSON.stringify(response.data.accessToken),
        )
        this.userAuthToken = JSON.stringify(response.data.accessToken)
        this.getSignyDocument(response.data.sessionUser)

        // useRouter().push('/auth/sign_fast_change')
      }
    },
    async facebookSignInUser() {
      this.isSignByToken = false
      const response: any = await http.post('/auth/facebook-sign-in', {
        accessToken: this.facebookToken,
      })
      if (response.data.accessToken) {
        localStorage.setItem(
          'userAuthToken',
          JSON.stringify(response.data.accessToken),
        )
        this.userAuthToken = JSON.stringify(response.data.accessToken)
        this.getSignyDocument(response.data.sessionUser)
        // useRouter().push('/auth/sign_fast_change')
      }
    },
    async signUpUser(isVerified: boolean) {
      this.isSignByToken = false
      const isUsePhone =
        localStorage.getItem('usePhone') !== null &&
        localStorage.getItem('usePhone') !== undefined &&
        localStorage.getItem('usePhone') === 'true'
      const queryData = isUsePhone
        ? {
            phone: this.userPhone,
            password: this.userPassword,
            isVerified,
          }
        : {
            email: this.userEmail,
            password: this.userPassword,
            isVerified,
          }
      let wrongMessageAuth = ''
      try {
        const response: any = await http.post('/auth/sign-up', queryData)
        localStorage.setItem(
          'userAuthToken',
          JSON.stringify(response.data.accessToken),
        )
        this.userAuthToken = JSON.stringify(response.data.accessToken)

        if (!response.data.sessionUser.isEmailVerified) {
          localStorage.setItem(
            'emailConfirmStartTime',
            JSON.stringify(Date.now()),
          )
          isUsePhone
            ? localStorage.setItem('userPhone', JSON.stringify(this.userPhone))
            : localStorage.setItem('userEmail', JSON.stringify(this.userEmail))
          useRouter().push({
            path: '/auth/confirm',
            query: useRoute().query,
          })
        }
        const profileStore = useProfileStore()
        profileStore.setUserLang('he')
      } catch (e: any) {
        if (e.response) {
          wrongMessageAuth =
            e.response.data.errorCode === 8
              ? isUsePhone
                ? 'Number is already registered'
                : 'Email is already registered'
              : ''
        }
      }
      this.wrongMessage = wrongMessageAuth
    },
    async checkUserExist(payload: any) {
      this.isSignByToken = false
      try {
        const { data } = await http.post('/auth/check-user-exists', payload)
        return data
      } catch (e) {
        console.error(e)
      }
    },
    async sendConfirmUser() {
      this.isSignByToken = false
      const isUsePhone =
        localStorage.getItem('usePhone') === 'true' ? true : false
      const queryData = isUsePhone
        ? {
            otpType: 'LAST_PHONE',
            code: this.userConfirmCode,
          }
        : {
            otpType: 'CURRENT_EMAIL',
            code: this.userConfirmCode,
          }
      const response: any = await axios.post(
        `${
          useRuntimeConfig().public.API_BASE_URL
        }/verification/verify-otp-code`,
        queryData,
        {
          headers: {
            'X-API-Key': useRuntimeConfig().public.X_API_KEY,
            'X-Session-Key': JSON.parse(
              localStorage.getItem('userAuthToken') || '',
            ),
          },
        },
      )

      const profileStore = useProfileStore()
      profileStore.setUserLang('he')

      // localStorage.setItem(
      //   'userAuthToken',
      //   JSON.stringify(response.data.sessionUser.sessionToken)
      // )
      // this.userAuthToken = JSON.stringify(
      //   response.data.sessionUser.sessionToken
      // )
      this.getSignyDocument({})
      // useRouter().push('/auth/sign_fast_change')
    },
    async resendConfirmUser() {
      this.isSignByToken = false
      const isUsePhone =
        localStorage.getItem('usePhone') === 'true' ? true : false
      const queryData = isUsePhone
        ? {
            otpType: 'LAST_PHONE',
            code: this.userConfirmCode,
          }
        : {
            otpType: 'CURRENT_EMAIL',
            code: this.userConfirmCode,
          }
      await axios.post(
        `${
          useRuntimeConfig().public.API_BASE_URL
        }/verification/resend-otp-code`,
        queryData,
        {
          headers: {
            'X-API-Key': useRuntimeConfig().public.X_API_KEY,
            'X-Session-Key': JSON.parse(
              localStorage.getItem('userAuthToken') || '',
            ),
          },
        },
      )
    },
    async resetPasswordUser() {
      this.isSignByToken = false
      const isUsePhone =
        localStorage.getItem('usePhone') === 'true' ? true : false
      const queryData = isUsePhone
        ? {
            phone: localStorage.getItem('userPhone'),
          }
        : {
            email: localStorage.getItem('userEmail'),
          }

      try {
        const response: any = await http.post('/auth/reset-password', queryData)
        if (response) {
          localStorage.setItem(
            'emailConfirmStartTime',
            JSON.stringify(Date.now()),
          )
          await useRouter().push('/auth/confirm')
        }
      } catch (error: any) {
        if (error.response) {
          if (
            error.response.data.errorCode === 6 ||
            error.response.data.errorCode === 4 ||
            error.response.data.errorCode === 3
          ) {
            this.wrongMessage = isUsePhone
              ? 'This number is not registered'
              : 'This email is not registered'
          }
        }
      }
    },
    async resetPasswordUserOTP() {
      this.isSignByToken = false
      const isUsePhone =
        localStorage.getItem('usePhone') === 'true' ? true : false
      const queryData = isUsePhone
        ? {
            phone: localStorage.getItem('userPhone'),
            code: this.userConfirmCode,
          }
        : {
            email: localStorage.getItem('userEmail'),
            code: this.userConfirmCode,
          }
      const response: any = await http.post(
        '/auth/verify-reset-password-otp',
        queryData,
      )
      localStorage.setItem(
        'userAuthToken',
        JSON.stringify(response.data.recoveryToken),
      )
      this.userAuthToken = JSON.stringify(response.data.recoveryToken)
      useRouter().push('/auth/reset_password')
      localStorage.removeItem('isUserRecover')
      // LAST_PHONE
    },
    async recoverPassword() {
      this.isSignByToken = false
      const queryData = {
        recoveryToken: JSON.parse(localStorage.getItem('userAuthToken') || ''),
        newPassword: this.userPassword,
      }
      try {
        const response: any = await http.post(
          '/auth/recover-password',
          queryData,
        )
        localStorage.removeItem('isUserRecover')
        if (response && response.data && response.data.sessionUser) {
          localStorage.setItem(
            'userAuthToken',
            JSON.stringify(response.data.accessToken),
          )
          this.userAuthToken = JSON.stringify(response.data.accessToken)
          this.getSignyDocument(response.data.sessionUser)
        } else {
          this.clearUser()
        }
      } catch (e) {
        console.error(e)
      }
    },
    async changePassword(data: any) {
      this.isSignByToken = false
      try {
        const response = await axios.post(
          `${useRuntimeConfig().public.API_BASE_URL}/user/change-password`,
          data,
          {
            headers: {
              'X-API-Key': useRuntimeConfig().public.X_API_KEY,
              'X-Session-Key': JSON.parse(
                localStorage.getItem('userAuthToken') || '',
              ),
            },
          },
        )
        return response
      } catch (e) {
        console.error(e)
      }
    },
    updateUser(payload: any) {
      this.userPhone = payload.userPhone
      this.userEmail = payload.userEmail
      this.userPassword = payload.userPassword
      this.userConfirmCode = payload.userConfirmCode
    },
    updateWrongMessageAuth(payload: any) {
      this.wrongMessage = payload.wrongMessage
    },
    // easily reset state using `$reset`
    clearUser() {
      this.$reset()
      localStorage.removeItem('userAuthToken')
      localStorage.removeItem('userEmail')
      localStorage.removeItem('userPhone')
      localStorage.removeItem('googleToken')
      localStorage.removeItem('emailConfirmStartTime')
      localStorage.removeItem('opened-pdf-docs')
      localStorage.removeItem('pdfjs.history')
      useRouter().push({
        path: '/auth/sign_in',
        query: {
          to: encodeURIComponent(useRoute().fullPath),
        },
      })
    },
    getSignyDocument(sessionUser: any) {
      const fileId = JSON.parse(localStorage.getItem('documentId') || 'null')
      if (fileId) {
        useRouter().push(`/signer/${fileId}`)
      } else {
        useRouter().push(`/signy/explorer`)
      }
    },
  },
})
