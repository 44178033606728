import { useAuthUserStore } from '~/stores/auth/auth-user'

export default defineNuxtRouteMiddleware((to, from) => {
  const store = useAuthUserStore()
  if (to.query.subscription_id) {
    localStorage.setItem('subscription_id', `${to.query.subscription_id}`)
  }
  if (
    ![
      'file-id',
      'auth-sign_in',
      'auth-sign_up',
      'auth-change_password',
      'auth-confirm',
      'auth-forgot_password',
      'signer-create_account',
      'signer-create_password',
      'signer-document_id',
      // 'auth-sign_fast_change',
    ].includes(to.name as string)
  ) {
    if (store.userAuthToken === '' && store.isSignByToken === false) {
      return navigateTo({ path: '/auth/sign_in' })
    }
  }
})
