import { defineStore } from 'pinia'
import http from '@/services/http-common'

interface State {
  profile: any
  shortFullName: string
  newIntegrationToken: string
}

export const useProfileStore = defineStore('useProfileStore', {
  state: (): State => ({
    profile: {},
    shortFullName: '',
    newIntegrationToken: '',
  }),
  actions: {
    async getUserProfile() {
      try {
        const response = await http.get('/user/current-user')

        if (!response.data || !response.data.sessionUser) {
          console.error('API didnt return data:', response.data)
          return
        }

        if (typeof response.data.sessionUser.fullName !== 'string') {
          console.error(
            'fullName не является строкой:',
            response.data.sessionUser.fullName,
          )
          return
        }

        this.profile = response.data

        const names = this.profile.sessionUser.fullName.trim().split(' ')
        if (!names.length) {
          console.error(
            'После разделения имен не найдено:',
            this.profile.sessionUser.fullName,
          )
          return
        }

        this.shortFullName = names
          .slice(0, 2)
          .map((name) => name[0])
          .join('')

        return response
      } catch (e) {
        console.error(e)
        return false
      }
    },
    async setUserPersonalInformation(data: any) {
      try {
        const response = await http.post(
          `/profile/set-user-personal-information`,
          data,
        )
      } catch (e) {
        console.error(e)
      }
    },
    async setUserAvatar(formData: any) {
      try {
        const response = await http.get(`/user/current-user`, formData)
      } catch (e) {
        console.error(e)
      }
    },
    async fetchUserSubscription() {
      try {
        const response = await http.get(`/subscriptions/user-subscription`)
        return response
      } catch (e) {
        console.error(e)
      }
    },
    async setDriveToken(token: string) {
      try {
        return await http.post(`/user/set-drive-token`, { token })
      } catch (e) {
        console.error(e)
      }
    },
    async setUserLang(lang: string) {
      try {
        return await http.post(`/user/set-user-lang`, { lang })
      } catch (e) {
        console.error(e)
      }
    },
    async fetchUserSubscriptionsPlans() {
      try {
        const response = await http.get(`/subscriptions/subscriptions-plans`)
      } catch (e) {
        console.error(e)
      }
    },
    async generateIntegrationToken(userId: number) {
      const response = await http.post(`/user/generate-integration-token`, {
        userId: userId,
      })
      this.newIntegrationToken = response.data.token
    },
  },
})
