import { defineStore } from 'pinia'
import { RouteLocationNormalized } from 'vue-router'

export const useRouterHistoryStore = defineStore('use-router-history', () => {
  const history = ref<RouteLocationNormalized[]>([])

  const lastRoute = computed<RouteLocationNormalized | null>(() =>
    history.value.length ? history.value[history.value.length - 1] : null,
  )

  return { history, lastRoute }
})
